<template>
  <v-container
    id="regular-forms"
    fluid
    tag="section"
  >
    <validation-observer
      ref="obs"
      v-slot="{ invalid, handleSubmit }"
    >
      <form>
        <!--  Administrateur-->
        <v-row
          v-if="ifRoleAdminAndRouteOrder"
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <base-material-card
              color="success"
              icon="mdi-lock-open"
              :title="$t('Administrator')"
              class="px-5 py-3 mt-3"
            >
              <!-- Statut-->
              <v-subheader class="display-1 mt-3">
                {{ $t('Status') }}
              </v-subheader>

              <validation-provider
                v-slot="{ errors, valid }"
                :name="$t('Status')"
                rules="required"
              >
                <v-select
                  v-model="submitForm.statut"
                  :disabled="true"
                  class="ml-4 mt-0"
                  :items="statusAdmin"
                  item-text="item"
                  item-value="valeur"
                  persistent-hint
                  single-line
                  :error-messages="errors"
                  :success="valid"
                  required
                />
              </validation-provider>

              <!-- No de commande du fournisseur-->
              <v-subheader class="display-1 mt-3">
                No CMD {{ parameterFournisseur.toUpperCase() }}
              </v-subheader>

              <v-text-field
                v-model="submitForm.fournisseur_no_commande"
                :disabled="state === 'view'"
                class="ml-4 mt-0"
              />
            </base-material-card>
          </v-col>
        </v-row>

        <!--  Statut et Type-->
        <!-- <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <base-material-card
              color="success"
              icon="mdi-order-bool-ascending"
              :title="$t('order.Equipment_purchase')"
              class="px-5 py-3 mt-3"
            >
              <v-subheader class="display-1 mt-3">
                {{ $t('order.Equipment_purchase') }}
              </v-subheader>

              <v-radio-group
                v-model="submitForm.type_equipement"
                :value="1"
                row
                class="ml-4 mt-0"
                @change="changeEquipment"
              >
                <v-radio
                  :label="$t('Device')"
                  :value="1"
                />
                <v-radio
                  :disabled="Boolean(!client.Contrats.length)"
                  :label="$t('Sim_card')"
                  :value="2"
                />
              </v-radio-group>

              <template v-if="submitForm.type_equipement === 2">
                <v-subheader class="display-1 mt-3">
                  {{ $t('order.SIM_card_number') }}
                </v-subheader>

                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('order.SIM_card_number')"
                  rules="required"
                >
                  <v-select
                    v-model="submitForm.no_telephone"
                    class="ml-4 mt-0"
                    :placeholder="$t('order.Choose_phone_number')"
                    :disabled="state === 'view'"
                    :items="getPhoneNumberSimCard"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </template>
            </base-material-card>
          </v-col>
        </v-row> -->

        <!-- LIVRAISON/CUILLETTE-->
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <base-material-card
              color="green"
              icon="mdi-truck-fast"
              :title="$t('order.Pickup_Delivery')"
              class="px-5 py-3 mt-3"
            >
              <!-- On affiche le choix si il y a une cueillette et une livraison-->
              <validation-provider
                v-if="parameterPickupDelivery === '0'"
                v-slot="{ errors, valid }"
                :name="$t('order.Pickup_Delivery')"
                rules="required"
              >
                <v-radio-group
                  v-model="submitForm.livraison"
                  :disabled="state === 'view'"
                  row
                  class="ml-4 mt-3"
                  :error-messages="errors"
                  :success="valid"
                  required
                >
                  <!-- Cueillette-->
                  <v-radio
                    :label="$t('order.Pickup')"
                    :value="false"
                  />

                  <!-- Livraison-->
                  <v-radio
                    :label="$t('order.Delivery')"
                    :value="true"
                  />
                </v-radio-group>
              </validation-provider>

              <template v-if="submitForm.livraison !== null">
                <!-- Adresse pour la livraison-->
                <template v-if="submitForm.livraison">
                  <v-subheader class="display-1 mt-3">
                    {{ $t('order.Delivery') }}
                  </v-subheader>

                  <!-- Numéro de suivi-->
                  <!-- Pour les Opérations seulement-->
                  <v-text-field
                    v-if="ifRouteOrder"
                    v-model="submitForm.livraison_no_suivi"
                    :disabled="state === 'view'"
                    class="ml-4 mt-0"
                    :label="$t('order.Tracking_number')"
                  />

                  <!-- Nom-->
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('Name')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="submitForm.livraison_adresse.nom"
                      class="ml-4 mt-0"
                      :disabled="state === 'view'"
                      :label="$t('Name')"
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>

                  <!-- Prénom-->
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('FirstName')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="submitForm.livraison_adresse.prenom"
                      class="ml-4 mt-0"
                      :disabled="state === 'view'"
                      :label="$t('FirstName')"
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>

                  <!-- Adresse-->
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('client.Address')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="submitForm.livraison_adresse.adresse"
                      class="ml-4 mt-0"
                      :disabled="state === 'view'"
                      :label="$t('client.Address')"
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>

                  <!-- Ville-->
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('client.City')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="submitForm.livraison_adresse.ville"
                      class="ml-4 mt-0"
                      :disabled="state === 'view'"
                      :label="$t('client.City')"
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>

                  <!-- Province-->
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('client.City')"
                    rules="required"
                  >
                    <v-select
                      v-model="submitForm.livraison_adresse.province"
                      class="ml-4 mt-0"
                      :disabled="state === 'view'"
                      :items="provinces"
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>

                  <!-- Code Postale-->
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('client.ZIP_code')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="submitForm.livraison_adresse.code_postal"
                      class="ml-4 mt-0"
                      :disabled="state === 'view'"
                      :label="$t('client.ZIP_code')"
                      :error-messages="errors"
                      :success="valid"
                      placeholder="H0H 0H0"
                      required
                    />
                  </validation-provider>
                </template>

                <!-- Cueillette-->
                <template v-else>
                  <v-subheader class="display-1 mt-3">
                    {{ $t('order.Pickup') }}
                  </v-subheader>

                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('Counter')"
                    rules="required"
                  >
                    <v-list
                      shaped
                    >
                      <v-list-item-group
                        v-model="submitForm.ComptoirId"
                        class="ml-4 mt-0"
                        :error-messages="errors"
                        :success="valid"
                        required
                      >
                        <template v-for="(item, i) in getComptoirList">
                          <v-divider
                            v-if="!item"
                            :key="`divider-${i}`"
                          />

                          <v-list-item
                            v-else
                            :key="`item-${i}`"
                            :disabled="state === 'view' || item.id === submitForm.ComptoirId || getComptoirList.length === 1"
                            :value="item.id"
                            active-class="green--text text--accent-4"
                          >
                            <template #default="{ active }">
                              <v-list-item-action>
                                <v-checkbox
                                  :input-value="active"
                                  color="green"
                                />
                              </v-list-item-action>

                              <v-list-item-content>
                                <v-list-item-title v-html="item[language]" />
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </validation-provider>
                </template>
              </template>
            </base-material-card>
          </v-col>
        </v-row>

        <!-- FACTURATION-->
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <base-material-card
              color="success"
              icon="mdi-book-open-variant"
              :title="$t('order.Billing_code')"
              class="px-5 py-3 mt-3"
            >
              <validation-provider
                v-slot="{ errors, valid }"
                name="UBR"
                :rules="getUbrValidationRules"
              >
                <v-text-field
                  v-model="submitForm.code_facturation"
                  :disabled="state === 'view'"
                  class="mt-3"
                  :maxlength="parameterLongueurCodeFacturation"
                  :label="$t('order.ubr_description')"
                  :error-messages="errors"
                  :success="valid"
                  required
                />

                <!-- Responsable de l'UBR-->
                <div v-if="ubrResponsableMessage">
                  <v-icon color="success">
                    mdi-check
                  </v-icon>
                  <span class="success--text">{{ ubrResponsableMessage }}</span>
                </div>
              </validation-provider>

              <!-- Validation UBR-->
              <template v-if="ubrValidation">
                <div class="mt-5">
                  <v-icon color="orange">
                    mdi-alert
                  </v-icon>
                  <span class="red--text ml-1">{{ ubrValidationMessage === '' ? 'ATTENTION' : 'ATTENTION:' }}</span>

                  <p class="text-justify red--text">
                    {{ ubrValidationMessage }}
                  </p>
                </div>

                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('order.validation.Billing_confirmation')"
                  rules="required"
                >
                  <v-checkbox
                    v-if="ubrValidationConfirmation && validationUBRCheckbox"
                    v-model="submitForm.code_facturation_confirmation"
                    :disabled="state === 'view'"
                    class="mt-0"
                    :value="true"
                    :error-messages="errors"
                    :success="valid"
                    required
                  >
                    <span
                      slot="label"
                      class="red--text"
                    >{{ $t('order.validation.condition_accepted') }}</span>
                  </v-checkbox>
                </validation-provider>
              </template>
            </base-material-card>
          </v-col>
        </v-row>

        <!-- Coût-->
        <!-- Pour les Opérations seulement-->
        <v-row
          v-if="ifRouteOrder"
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <base-material-card
              color="success"
              icon="mdi-currency-usd"
              :title="submitForm.type_equipement === 1 ? $t('order.operation.Device_cost') : $t('order.operation.Sim_card_cost')"
              class="px-5 py-3 mt-3"
            >
              <!-- Coût-->
              <validation-provider
                v-slot="{ errors, valid }"
                :name="submitForm.type_equipement === 1 ? $t('order.operation.Device_cost_before_tx') : $t('order.operation.Sim_card_cost_before_tx')"
                rules="required"
              >
                <!-- Coût de l'appareil-->
                <v-text-field
                  v-model="submitForm.cout_at"
                  :disabled="submitForm.type_equipement === 2"
                  class="mt-3"
                  type="number"
                  :label="submitForm.type_equipement === 1 ? $t('order.operation.Device_cost_before_tx') : $t('order.operation.Sim_card_cost_before_tx')"
                  :error-messages="errors"
                  :success="valid"
                  required
                  @input="changeCost"
                >
                  <v-icon
                    slot="prepend"
                    color="green"
                  >
                    mdi-currency-usd
                  </v-icon>
                </v-text-field>
              </validation-provider>

              <!-- TVQ-->
              <v-text-field
                v-model="submitForm.cout_tvq"
                disabled
                label="TVQ"
              >
                <v-icon
                  slot="prepend"
                  color="green"
                >
                  mdi-currency-usd
                </v-icon>
              </v-text-field>

              <!-- TPS-->
              <v-text-field
                v-model="submitForm.cout_tps"
                disabled
                label="TPS"
              >
                <v-icon
                  slot="prepend"
                  color="green"
                >
                  mdi-currency-usd
                </v-icon>
              </v-text-field>

              <!-- Coût total-->
              <v-text-field
                v-model="submitForm.cout_total"
                disabled
                :label="$t('order.operation.Total_cost')"
              >
                <v-icon
                  slot="prepend"
                  color="green"
                >
                  mdi-currency-usd
                </v-icon>
              </v-text-field>

              <!-- Coût ristourné-->
              <v-text-field
                v-model="submitForm.cout_ristourne"
                disabled
                :label="$t('order.operation.Discounted_cost')"
              >
                <v-icon
                  slot="prepend"
                  color="green"
                >
                  mdi-currency-usd
                </v-icon>
              </v-text-field>

              <!-- Coût facturé-->
              <v-text-field
                v-model="submitForm.cout_facture"
                disabled
                :label="$t('order.operation.Invoiced_cost')"
              >
                <v-icon
                  slot="prepend"
                  color="green"
                >
                  mdi-currency-usd
                </v-icon>
              </v-text-field>
            </base-material-card>
          </v-col>
        </v-row>

        <!-- Description de l'équipement-->
        <!-- Si c'est un équipement de téléphone on montre la description-->
        <v-row
          v-if="submitForm.type_equipement !== 2"
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <base-material-card
              color="success"
              icon="mdi-cellphone"
              :title="$t('order.Device_description')"
              class="px-5 py-3 mt-3"
            >
              <p class="ml-4 mt-4">
                {{ $t('order.Equipment_list').toUpperCase() }}
                <a
                  href="https://www.telus.com/fr/mobility/phones/?linktype=nav&INTCMP=LNK_frmCTA_TopNavigationAll_toPhones"
                  target="_blank"
                  rel="noopener"
                >{{ $t('order.Equipments_list').toUpperCase() }}.</a>
              </p>
              <p class="ml-4">
                {{ $t('order.Equipment_message').toUpperCase() }}
              </p>
              <p class="ml-4 mt-4">
                {{ $t('order.Device_description_info').toUpperCase() }}
              </p>
              <validation-provider
                v-slot="{ errors, valid }"
                :name="$t('order.Device_description')"
                rules="required"
              >
                <v-text-field
                  v-model="submitForm.description_equipement"
                  :disabled="state === 'view'"
                  outlined
                  class="ml-4 mt-3"
                  :label="$t('order.Device_description')"
                  :error-messages="errors"
                  :success="valid"
                  required
                />
              </validation-provider>
            </base-material-card>
          </v-col>
        </v-row>

        <!-- Commentaire-->
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <base-material-card
              color="success"
              icon="mdi-comment"
              :title="$t('Comment')"
              class="px-5 py-3 mt-3"
            >
              <v-textarea
                v-model="submitForm.note"
                :disabled="state === 'view'"
                outlined
                class="ml-4 mt-3"
                :label="$t('order.Equipment_description')"
                rows="5"
                auto-grow
                append-icon="mdi-comment"
              />
            </base-material-card>
          </v-col>
        </v-row>

        <!-- CONFIRMATION-->
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              v-if="state !== 'view'"
              :disabled="invalid"
              class="mt-3"
              color="success"
              @click="handleSubmit(submit)"
            >
              {{ $t('Validate') }}
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </validation-observer>
  </v-container>
</template>

<script>
  // Services
  import CommandesService from '@/services/01Cell/CommandesService'
  import MessagesService from '@/services/01Cell/MessagesService'
  import ListService from '@/services/01Cell/ListService'
  // Mixins
  import { orders } from '@/mixins/Client/Order/orders'
  import { ClientEdit } from '@/mixins/Client/ClientEdit'
  import { ordersUbrRules } from '@/mixins/Client/Order/ordersUbrRules'
  // Other
  import { mapGetters } from 'vuex'
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'

  export default {
    name: 'OrderNewPhoneUbr',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [orders, ClientEdit, ordersUbrRules],
    props: {
      client: Object,
      state: String,
      parameterPickupDeliveryType3: String,
      parameterLongueurCodeFacturation: String,
    },
    data () {
      return {
        language: this.$i18n.t('Language'),
        submitForm: {},
        messageConfirmation: null,
        ubrResponsableMessage: null,
        ubrValidation: false,
        ubrValidationConfirmation: false,
        ubrValidationMessage: '',
        tauxRistourneTPS: null,
        tauxRistourneTVQ: null,
        tauxRistourne: null,
        prixSimCard: null,
        pickupComptoir: [],
        parameterPickupDelivery: null,
        compteUQAM: '54914',
      }
    },

    computed: {
      ...mapGetters('parameters', {
        parameterFournisseur: 'getFournisseur',
      }),
      getPhoneNumberSimCard () {
        const phoneNumbers = this.client.Contrats.filter(item => item.statut === 1)
          .map(item => {
            item.no = `(${item.no.slice(0, 3)}) ${item.no.slice(3, 6)}-${item.no.slice(6)}`
            return item
          })
        return Array.from(phoneNumbers, x => x.no).sort()
      },
      validationUBRCheckbox () {
        // Si il y a des erreurs dans la validation on affiche pas le bouton
        if (this.$refs.obs.errors.UBR) {
          if (this.$refs.obs.errors.UBR.length) return false
        }
        return true
      },
    },

    watch: {
      'submitForm.cout_at' (val, oldValue) {
        if (oldValue) {
          if (oldValue !== val) {
            const cost = val || 0
            this.addCost(cost)
          }
        }
      },
      // Validation du UBR
      'submitForm.code_facturation' (val) {
        if (!val) return

        // L'UQAM, On récupère le responsable de l'UBR
        if (this.$company === 'uqam') {
          // On récupère le responsable de l'UBR
          this.ubrResponsableMessage = null
          if (val.length === 6 && val !== '000000') {
            // On regarde si la val est un chiffre
            if (!isNaN(val)) {
              CommandesService.responsableCodeFacturation(val).then(response => {
                if (response) {
                  this.ubrResponsableMessage = `${this.$i18n.t('order.validation.valid_ubr')} (${response})`
                } else {
                  this.ubrValidation = true
                  this.ubrValidationMessage = ''
                }
              })
            }
          }

          if (val.startsWith('3') || val.startsWith('000000')) {
            this.ubrValidation = true
            this.ubrValidationConfirmation = true
            if (val.startsWith('37') || val.startsWith('35') || val.startsWith('000000')) {
              this.ubrValidationMessage = this.$i18n.t('order.validation.ubr3537')
              // Pour l'utilisateur qui commande, on mets le UBR '000000'. Si c'est un admin il peut mettre ce qu'il veux
              if (this.$route.name === 'Dashboard') this.submitForm.code_facturation = '000000'
            } else {
              this.ubrValidationMessage = this.$i18n.t('order.validation.ubr3or7')
            }
            return
          }

          if (val.startsWith('7')) {
            this.ubrValidation = true
            this.ubrValidationConfirmation = true
            this.ubrValidationMessage = this.$i18n.t('order.validation.ubr3or7')
            return
          }
          this.ubrValidation = false
        }
      },
      // 'submitForm.type_equipement' (val) {
      //   // Si le type d'équipement est null, on regarde si il y a des contrats
      //   if (!val) {
      //     // Si il y a pas de contrat, on mets le type d'équipent 1 (Appareil)
      //     if (!this.client.Contrats.length) this.submitForm.type_equipement = 1
      //   }
      // },
    },

    async mounted () {
      // Livraison
      this.parameterPickupDelivery = this.parameterPickupDeliveryType3

      // Start Spinner
      this.$loading.show()
      try {
        // On récupère des comptoirs
        this.pickupComptoir = await ListService.search('commandes_comptoir')

        // Reset des infos si c'est une nouvelle commande
        if (this.state === 'create') this.resetSubmitForm(3)
        // Par défaut, équipement -> Appareil téléphonique
        this.submitForm.type_equipement = 1
        // On récupère le message de confirmation
        MessagesService.search(10).then(response => {
          this.messageConfirmation = response
        })

        // Stop Spinner
        this.$loading.hide()
      } catch (error) {
        console.log(error)
        // Stop Spinner
        this.$loading.hide()
      }
    },

    methods: {
      changeEquipment (event) {
        // Si le client à choisi une carte SIM
        if (event === 2) {
          this.submitForm.description_equipement = this.$i18n.t('Sim_card')
          // On mets le prix de la carte
          this.submitForm.cout_at = this.prixSimCard
          this.addCost(this.submitForm.cout_at)
        } else {
          this.submitForm.no_telephone = null
          this.submitForm.no_carte_sim = null
          this.submitForm.description_equipement = null
          // On reset le prix
          this.submitForm.cout_at = null
          this.addCost(this.submitForm.cout_at)
        }
      },
      async submit () {
        if (this.state === 'create') {
          this.submitForm.ClientId = this.client.id
          this.submitForm.type = 3
          this.submitForm.mode_paiement = 1

          // Si le c'est une carte SIM
          if (this.submitForm.type_equipement === 2) {
            // On mets le prix d'après le paramètre
            this.submitForm.cout_at = this.prixSimCard
            // On calcule les taxes
            this.addCost(this.submitForm.cout_at)
          }

          // Message
          // On soumets la commande. Voir le composant 'components/Client/Order/demoUqam/Order.vue'
          this.$emit('submit', this.submitForm, this.messageConfirmation[this.language])
        } else {
          // On soumets la commande. Voir le composant '/components/Client/Order/MyOrders.vue'
          this.$emit('submit', this.submitForm)
        }
      },
      changeCost () {
        this.addCost(this.submitForm.cout_at)
      },
    },
  }
</script>
