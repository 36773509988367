<template>
  <v-container
    v-if="client"
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        class="text-right"
      >
        <v-btn-toggle
          v-model="language"
          mandatory
        >
          <v-btn
            value="fr"
            text
            color="info"
            @click="changeLanguage('fr')"
          >
            {{ $t('French') }}
          </v-btn>
          <v-btn
            value="en"
            text
            color="info"
            @click="changeLanguage('en')"
          >
            {{ $t('English') }}
          </v-btn>
        </v-btn-toggle>
      </v-col>

      <!-- Bouton-->
      <!-- Mobile-->
      <v-col
        v-if="isMobile"
        cols="12"
        class="text-center"
      >
        <!-- Mobile-->
        <v-btn-toggle
          v-model="toggleButton"
          mandatory
        >
          <!--Contrat -->
          <v-btn
            value="contrat"
            text
            width="35"
            color="blue"
            @click="toggleButton = 'contrat'"
          >
            <v-icon
              center
              color="blue"
            >
              mdi-phone
            </v-icon>
          </v-btn>

          <!-- Autre facture-->
          <v-btn
            value="factureAutre"
            text
            width="35"
            color="blue"
            @click="toggleButton = 'factureAutre'"
          >
            <v-icon
              center
              color="blue"
            >
              mdi-file-document-outline
            </v-icon>

            <!-- Îcone pour les facture non payé-->
            <v-icon
              v-if="unpaidUnvoiceState"
              class="ml-1"
              left
              dark
              small
              color="yellow accent-3"
            >
              mdi-alert
            </v-icon>
          </v-btn>

          <!-- Mes Commandes-->
          <!-- On affiche pas si il n'y a pas de commandes. Je l'ai enlevé-->
          <!-- v-if="client.Commandes.length" -->
          <v-btn
            value="mesCommandes"
            text
            width="35"
            color="blue"
            @click="toggleButton = 'mesCommandes'"
          >
            <v-icon
              center
              color="blue"
            >
              mdi-cart-outline
            </v-icon>

            <!-- On regarde si il y a une commande avec le status 2-->
            <v-icon
              v-if="checkCommandeStatus2"
              class="ml-1"
              left
              dark
              small
              color="yellow accent-3"
            >
              mdi-alert
            </v-icon>
          </v-btn>

          <!-- Commander (Un ex employé ne peut pas rien commander)-->
          <v-btn
            :disabled="client.type === '3'"
            value="commande"
            text
            width="35"
            color="blue"
            @click="toggleButton = 'commande'"
          >
            <v-icon
              center
              color="blue"
            >
              mdi-cart-plus
            </v-icon>
          </v-btn>

          <!-- Commandes pour les responsable-->
          <v-btn
            v-if="ifUbrResponsable"
            value="commandesResponsable"
            text
            width="35"
            color="blue"
            @click="toggleButton = 'commandesResponsable'"
          >
            <v-icon
              center
              color="blue"
            >
              mdi-cart-check
            </v-icon>

            <!-- On regarde si il y a des commandes à approuver pour le responsable-->
            <v-icon
              v-if="ubrResponsableOrders.length"
              class="ml-1"
              left
              dark
              small
              color="yellow accent-3"
            >
              mdi-alert
            </v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>

      <!-- Pas mobile-->
      <v-col
        v-else
        cols="12"
      >
        <!-- Pas mobile-->
        <v-btn-toggle
          v-model="toggleButton"
          mandatory
        >
          <!--Contrat -->
          <v-btn
            value="contrat"
            text
            color="blue"
            @click="toggleButton = 'contrat'"
          >
            <v-icon
              center
              class="mr-1"
              color="blue"
            >
              mdi-phone
            </v-icon>

            {{ $t('sidebar.my_contracts') }}
          </v-btn>

          <!-- Autre facture-->
          <v-btn
            value="factureAutre"
            text
            color="blue"
            @click="toggleButton = 'factureAutre'"
          >
            <v-icon
              center
              class="mr-1"
              color="blue"
            >
              mdi-file-document-outline
            </v-icon>

            <!-- UnPaid-->
            <v-icon
              v-if="unpaidUnvoiceState"
              left
              dark
              color="yellow accent-3"
            >
              mdi-alert
            </v-icon>
            {{ $t('invoices.Other_invoices') }}
          </v-btn>

          <!-- Mes Commandes-->
          <!-- On affiche pas si il n'y a pas de commandes. Je l'ai enlevé-->
          <!-- v-if="client.Commandes.length" -->
          <v-btn
            value="mesCommandes"
            text
            color="blue"
            @click="toggleButton = 'mesCommandes'"
          >
            <v-icon
              center
              class="mr-1"
              color="blue"
            >
              mdi-cart-outline
            </v-icon>

            <!-- On regarde si il y a une commande avec le status 2-->
            <v-icon
              v-if="checkCommandeStatus2"
              left
              dark
              color="yellow accent-3"
            >
              mdi-alert
            </v-icon>
            {{ $t('order.My_orders') }}
          </v-btn>

          <!-- Commander (Un ex employé ne peut rien pas commander)-->
          <v-btn
            :disabled="client.type === '3'"
            value="commande"
            text
            color="blue"
            @click="toggleButton = 'commande'"
          >
            <v-icon
              center
              class="mr-1"
              color="blue"
            >
              mdi-cart-plus
            </v-icon>

            {{ $t('order.name') }}
          </v-btn>

          <!-- Commandes pour les responsable-->
          <v-btn
            v-if="ifUbrResponsable"
            value="commandesResponsable"
            text
            color="blue"
            @click="toggleButton = 'commandesResponsable'"
          >
            <v-icon
              center
              color="blue"
            >
              mdi-cart-check
            </v-icon>

            <!-- On regarde si il y a des commandes à approuver pour le responsable-->
            <v-icon
              v-if="ubrResponsableOrders.length"
              class="ml-1"
              left
              dark
              small
              color="yellow accent-3"
            >
              mdi-alert
            </v-icon>

            {{ $t('order.commandesResponsable') }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-container
        id="client"
        fluid
        tag="section"
      >
        <!--  On regarde si il y a des contrats-->
        <template v-if="client.Contrats">
          <!--  Facture autres-->
          <facture-autre
            v-show="toggleButton === 'factureAutre'"
            :client="client"
            :client-id="client.id"
            :parameter-fournisseur-paiement="parameterFournisseurPaiement"
            :parameter-mode-paiement="parameterModePaiement"
            @unpaid-invoice="unpaidUnvoice"
            @return-to-contract="toggleButton = 'contrat'"
          />
          <!--  Contract-->
          <contract
            v-show="toggleButton === 'contrat'"
            :client-contrats="client.Contrats"
          />
          <!--  Commande-->
          <order
            v-show="toggleButton === 'commande'"
            ref="order"
            :client="client"
          />
          <!--  Mes commandes-->
          <my-orders
            v-show="toggleButton === 'mesCommandes'"
            :client="client"
            :order-step="orderStep"
          />

          <!-- Commandes pour les responsable-->
          <responsible-orders
            v-show="toggleButton === 'commandesResponsable'"
            :ubr-responsable-orders="ubrResponsableOrders"
            :ubr-responsable="ubrResponsable"
            @remove-responble-ubr-item="removeResponbleUbrItem"
          />
        </template>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
  // Components
  import Contract from '@/components/Client/Contract'
  import FactureAutre from '@/components/Client/Facture/FactureAutre'
  import Order from '@/components/Client/Order/Order'
  import MyOrders from '@/components/Client/Order/MyOrders'
  import ResponsibleOrders from '@/components/Client/Order/ResponsibleOrders'
  // Service
  import ClientsService from '@/services/01Cell/ClientsService'
  import CommandesService from '@/services/01Cell/CommandesService'
  // Mixins
  import { Payment } from '@/mixins/Payment/Payment'
  // Other
  import { mapState, mapGetters } from 'vuex'
  import { EventBus } from '@/utils/event-bus'
  import { isMobile } from 'mobile-device-detect'
  import { localize } from 'vee-validate'
  import Swal from 'sweetalert2'
  // Utils
  import { orderStep } from '@/utils/constants/order/step'

  export default {
    name: 'Dashboard',
    components: {
      Contract,
      FactureAutre,
      Order,
      MyOrders,
      ResponsibleOrders,
    },
    mixins: [Payment],
    data () {
      return {
        orderStep: orderStep,
        language: this.$i18n.t('Language'),
        isMobile: isMobile,
        client: {
          Commandes: [],
        },
        toggleButton: 'contrat', // contrat, factureAutre ou commande
        unpaidUnvoiceState: false,
        ifUbrResponsable: false,
        ubrResponsableOrders: [],
        ubrResponsable: [],
      }
    },

    computed: {
      ...mapState(['userInfo']),
      ...mapGetters('parameters', {
        parameterFournisseurPaiement: 'getFournisseurPaiement',
        parameterModePaiement: 'getModePaiement',
        parameterLanguage: 'getLanguage',
      }),
      checkCommandeStatus2 () {
        // Si Commande n'est pas défini, on retourne false
        if (!this.client.Commandes) return false
        // On regarde si il y a une commande avec la variable iconMesCommandes est true. si oui on affiche un icone de warning
        return this.client.Commandes.find(item => this.orderStep.type[item.type].statut[item.statut].etape[item.etape].mesCommandes.action.iconMesCommandes)
      },
    },

    watch: {
      'toggleButton' (value) {
        if (!value) this.toggleButton = 'contrat'
      },
    },

    mounted () {
      // Start Spinner
      this.$loading.show()

      // Récupération des infos sur le client
      this.getData()

      // Viens du composant 'components/Client/Order/MyOrders.vue'
      EventBus.$on('update-order', () => {
        // Start Spinner
        this.$loading.show()
        // Récupération des infos sur le client
        this.getData()
      })
    },

    destroyed () {
      // Viens du composant 'components/Client/Order/MyOrders.vue'
      EventBus.$off('update-order')
    },

    methods: {
      async getData () {
        try {
          // Commandes pour les responsable d'UBR
          // On regarde si l'utilisateur est responsable de UBR. Si oui et qu'il y a des commandes dont l'étape demande une confirmation, on affiche les commandes.
          if (this.$company === 'uqam') {
            CommandesService.ubrResponsableUqam().then(response => {
              this.ubrResponsableOrders = response.commandes
              this.ubrResponsable = response.ubr
              this.ifUbrResponsable = response.responsable

              // Si la route est 'DashboardResponsable', on affiche le bouton 'Commandes à approuver'
              if (this.$route.name === 'DashboardResponsable') {
                this.toggleButton = 'commandesResponsable'
                this.$router.push({ name: 'Dashboard' })
              }
            })
          } else {
            // On regarde si l'utilisateur à le rôle 'admin_ubr'
            if (this.userInfo.info.roles.some(role => role === 'admin_ubr')) {
              // Si la route est 'DashboardResponsable', on affiche le bouton 'Commandes à approuver'
              if (this.$route.name === 'DashboardResponsable') {
                this.toggleButton = 'commandesResponsable'
                this.$router.push({ name: 'Dashboard' })
              }
              // On récupère les commandes en attente d'approbation d'un responsable d'UBR
              const response = await CommandesService.ubrResponsable()
              this.ubrResponsableOrders = response.commandes
              this.ifUbrResponsable = response.responsable
            }
          }

          const client = await ClientsService.query(this.userInfo.info.username, 'username')
          // Si le client est dans la base de donnée, il faut qu'il valide son profil si il manque des champs
          if (client) {
            this.client = client
            // Si le client est type 0, on mets à jour ses informations
            if (client.type === '0') {
              // Mise à jour du client avec le type 1
              ClientsService.update({
                nom: this.userInfo.info.name,
                prenom: this.userInfo.info.firstName,
                langue: this.parameterLanguage,
                email: this.userInfo.info.mail,
                type: '1', // Employé
              }, client.id)

              // Mise à jour des infos
              this.client.nom = this.userInfo.info.name
              this.client.prenom = this.userInfo.info.firstName
              this.client.langue = this.parameterLanguage
              this.client.email = this.userInfo.info.mail
              this.client.type = '1'
            } else if (parseInt(client.type, 10) === 2 || parseInt(client.type, 10) === 3) {
              // Si retraité (2) ou ex-employé (3), on veux que l'utilisateur utilise une adresse courriel autre que l'UQAM
              if (this.$company === 'uqam') {
                // Si c'est une adresse de l'UQAM, on redirige l'utilisateur dans le profile pour qu'il change de courriel
                if (client.email.includes('uqam.ca')) {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Attention',
                    html: `${this.$i18n.t('profile.swal.text.ex_employe_courriel')} <b>${this.$i18n.t(`dashboard.Type_employee.${client.type}`)}</b> <br> <br> ${this.$i18n.t('profile.swal.text.ex_employe_courriel1')}`,
                    heightAuto: false, // Put this each time. Prevents the html footer from being higher
                  })

                  this.$router.push({ name: 'profile' })
                  return
                }
              }
            } else {
              this.client = client
            }
          } else {
            // Création du client
            await ClientsService.save({
              username: this.userInfo.info.username,
              nom: this.userInfo.info.name,
              prenom: this.userInfo.info.firstName,
              langue: this.parameterLanguage ? this.parameterLanguage : 'fr',
              email: this.userInfo.info.mail,
              type: '1', // Employé
            })

            this.client = await ClientsService.query(this.userInfo.info.username, 'username')
          }

          // Stop Spinner
          this.$loading.hide()

          // Important on mets à jour les commandes pour les commandes payer par carte de crédit. Voir composant 'src/components/Client/Order/Order.vue'
          this.$nextTick(() => {
            this.$refs.order.updateOrder(this.client)
          })
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      removeResponbleUbrItem (id) {
        this.ubrResponsableOrders = this.ubrResponsableOrders.filter(x => x.id !== id)
      },
      unpaidUnvoice (state, clientId) {
        this.unpaidUnvoiceState = state
      },
      changeLanguage (lang) {
        // On change le language de Vuetify
        this.$vuetify.lang.current = lang
        // Vee-validate
        localize(lang)
        // RTL
        this.$vuetify.rtl = false
        // I18N
        this.$i18n.locale = lang
        // Local Storage
        localStorage.setItem('APP_LANG_KEY', lang)
      },
    },
  }
</script>
