// Services
import ParametersService from '@/services/01Cell/ParametersService'
// Other
import { mapState } from 'vuex'
import { parseISO, format } from 'date-fns'

export const orders = {
    data () {
        return {
          statusAdmin: [
              {
                item: this.$i18n.t('order.operation.status.1'),
                valeur: 1,
              },
              {
                item: this.$i18n.t('order.operation.status.2'),
                valeur: 2,
              },
              {
                item: this.$i18n.t('order.operation.status.3'),
                valeur: 3,
              },
              {
                item: this.$i18n.t('order.operation.status.4'),
                valeur: 4,
              },
              {
                item: this.$i18n.t('order.operation.status.5'),
                valeur: 5,
              },
              {
                item: this.$i18n.t('order.operation.status.6'),
                valeur: 6,
              },
              {
                item: this.$i18n.t('order.operation.status.7'),
                valeur: 7,
              },
              {
                item: this.$i18n.t('order.operation.status.8'),
                valeur: 8,
              },
              {
                item: this.$i18n.t('order.operation.status.-1'),
                valeur: -1,
              },
          ],
        }
    },

    computed: {
      ...mapState(['userInfo']),
      ifRoleAdminAndRouteOrder () {
        // Seulement pour le rôle 'admin' et la route 'operation-commande'
        if (this.$route.name === 'operation-commande' && this.userInfo.info.roles.some(item => item === 'admin')) return true
        return false
      },
      ifRouteOrder () {
        // Seulement si la route est 'operation-commande'
        if (this.$route.name === 'operation-commande') return true
        return false
      },
      ifNoTelephoneExist () {
        if (this.$route.name === 'operation-commande') return { required: true, min: 10, max: 10, numeric: true }
        return { required: true, min: 10, max: 10, numeric: true, numberPhoneIsDuplicates: this.submitForm.no_telephone }
      },
      specialProvider () {
        if (!this.submitForm.migration_fournisseur) return false
        return (this.submitForm.migration_fournisseur.toLowerCase() === 'pc mobile' || this.submitForm.migration_fournisseur.toLowerCase() === 'public mobile' || this.submitForm.migration_fournisseur.toLowerCase() === 'koodo' || this.submitForm.migration_fournisseur.toLowerCase() === 'telus')
      },
      getComptoirList () {
        // Si c'est en mode 'view' et qu'il y a un comptoir, on retourne seulement le comptoir
        if (this.state === 'view' && this.submitForm.ComptoirId) return [this.submitForm.Comptoir]
        // Un retourne tous les comptoir
        return this.pickupComptoir
      },
      showPickupDelivery () {
        // Si c'est un transfert de ligne (type 2) et que c'est le même fournisseur que le fournisseur officiel, on affiche pas la livraison et la cueillette
        if (this.submitForm.migration_fournisseur) {
          if (this.submitForm.type === 2 && this.submitForm.migration_fournisseur.toLowerCase() === this.$fournisseur.toLowerCase()) return false
        }
        return true
      },
    },

    mounted () {
      // Taxes et prix pour la SIM card
      this.getParameters()
    },

    methods: {
      resetSubmitForm (type) {
        // Reset da la validation
        this.$refs.obs.reset()

        // Paramètre de livraison
        if (type === 3) this.parameterPickupDelivery = this.parameterPickupDeliveryType3
        if (type !== 3) this.parameterPickupDelivery = null

        this.submitForm = {
          type: type,
          paiement_automatique: null,
          livraison: null,
          contrat_label: `${this.client.prenom} ${this.client.nom}`,
          type_equipement: null,
        }
        // this.submitForm.contrat_label = `${this.client.prenom} ${this.client.nom}`

        // Par défaut on mets l'adresse du client
        this.submitForm.livraison_adresse = {
          nom: this.client.nom,
          prenom: this.client.prenom,
          adresse: this.client.adresse,
          ville: this.client.ville,
          province: this.client.province,
          code_postal: this.client.code_postal,
        }

        // Définir le champs livraison
        this.setPickupDelivery()
        // Définir le mode de paiement
        this.setPaiment()
        // Définir la carte de crédit
        this.setCreditCard()
      },
      setPaiment () {
        // Si 'paiement_automatique' n'a pas encore été choisi, on regarde le paramètre 'parameterModePaiement' pour changer la variable 'paiement_automatique'
        if (!this.submitForm.paiement_automatique) {
          if (this.parameterModePaiement === '1') this.submitForm.paiement_automatique = false
          if (this.parameterModePaiement === '2') this.submitForm.paiement_automatique = true
        }
      },
      setCreditCard () {
        // On mets la carte de crédit par défaut si il n'y en a seulement 1 et que le mode de paiment est automatique
        if (this.client.Inscription_paiements) {
          if (this.client.Inscription_paiements.length === 1 && this.submitForm.paiement_automatique) this.submitForm.InscriptionPaiementId = this.client.Inscription_paiements[0].id
        }
      },
      editOrder (order) {
        this.submitForm = JSON.parse(JSON.stringify(order))
        // Si il y a une date de rendez-vous, on sépare la date et l'heure
        if (this.submitForm.rdv_date) {
          this.submitForm.date = format(parseISO(this.submitForm.rdv_date), 'yyyy-MM-dd')
          this.submitForm.time = format(parseISO(this.submitForm.rdv_date), 'HH:mm')
        }
      },
      setPickupDelivery () {
        // Si 'livraison' n'a pas encore été choisi, on regarde le paramètre 'parameterPickupDeliveryType2' pour changer la variable 'livraison'
        if (this.parameterPickupDelivery) {
          if (this.parameterPickupDelivery === '1') this.submitForm.livraison = false
          if (this.parameterPickupDelivery === '2') this.submitForm.livraison = true
        }

        // Si c'est un comptoir 'false'
        if (!this.submitForm.livraison) {
          // Si il n'y a que 1 comptoir dans la liste et que le comptoirId est null, on mets le comptoir par défaut
          if (this.pickupComptoir.length === 1 && !this.submitForm.ComptoirId) this.submitForm.ComptoirId = this.pickupComptoir[0].id
        }
      },
      addCost (coutAvantTaxe) {
        // Si le coût avant taxe est null
        if (!coutAvantTaxe) {
          // On reset les info
          this.submitForm.cout_tps = 0
          this.submitForm.cout_tvq = 0
          this.submitForm.cout_total = 0
          this.submitForm.cout_ristourne = 0
          this.submitForm.cout_facture = 0
          return
        }

        // Corporatif 1
        if (this.submitForm.mode_paiement === 1) {
          this.submitForm.cout_tps = 0
          this.submitForm.cout_tvq = 0
          this.submitForm.cout_total = (this.submitForm.cout_tps + this.submitForm.cout_tvq + parseFloat(coutAvantTaxe, 10)).toFixed(2)
          const ristourne = parseFloat(this.submitForm.cout_total, 10) + (this.submitForm.cout_total * this.tauxRistourne)
          this.submitForm.cout_ristourne = parseFloat(ristourne, 10).toFixed(2)
          this.submitForm.cout_facture = parseFloat(ristourne, 10).toFixed(2)
        } else if (this.submitForm.mode_paiement === 2) {
          console.log('bbbbb')
          // Corporatif 2
          this.submitForm.cout_tps = parseFloat(this.tauxRistourneTPS, 10) // A voir dans le futur
          this.submitForm.cout_tvq = parseFloat(this.tauxRistourneTVQ, 10) // A voir dans le futur
          this.submitForm.cout_total = (this.submitForm.cout_tps + this.submitForm.cout_tvq + parseFloat(coutAvantTaxe, 10)).toFixed(2)
          const ristourne = parseFloat(this.submitForm.cout_total, 10) + (this.submitForm.cout_total * this.tauxRistourne)
          this.submitForm.cout_ristourne = parseFloat(ristourne, 10).toFixed(2)
          this.submitForm.cout_facture = parseFloat(ristourne, 10).toFixed(2)
        }
      },
      getParameters () {
        // Récupération des taxes
        ParametersService.multiple({ parametres: [6, 7, 8, 16] }).then(response => {
          const tauxRistourneTPS = response.find(item => item.no === 6)
          this.tauxRistourneTPS = tauxRistourneTPS.valeur
          const tauxRistourneTVQ = response.find(item => item.no === 7)
          this.tauxRistourneTVQ = tauxRistourneTVQ.valeur
          const tauxRistourne = response.find(item => item.no === 8)
          this.tauxRistourne = tauxRistourne.valeur
          const prixSimCard = response.find(item => item.no === 16)
          this.prixSimCard = prixSimCard.valeur
        })
      },
    },
}
